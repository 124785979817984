/*
-----------------------------------------------------------------------
	About CSS
-----------------------------------------------------------------------
*/
$language-width: 55px;
$language-height: 25px;
$language-border: #ccc;
$language-hover: #eee;
$language-checked: #ddd;

// language styles

.language {
  width: $language-width * 2;
  height: $language-height;
  font-size: 14px;
  line-height: 1;
  margin: 1em 0;
}

.language__container--left,
.language__container--right {
  position: relative;
  float: left;
  width: $language-width;
  height: $language-height;
  padding: 5px;
}

.language__label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  color: #999999;
  width: $language-width;
  height: $language-height;
  border: 0px solid $language-border;
  padding: 7px 8px 5px 0;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: 7px 7px;
  background-size: 14px;
  text-align: right;
  text-transform: uppercase;
}

.language__container--left .language__label {
  border-radius: 0 0 0 0;
}

.language__container--right .language__label {
  border-left: 0;
  border-radius: 0 0 0 0;
}

.language__control:hover + .language__label {
  background-color: $language-hover;
}

.language__control:checked + .language__label {
//  background-color: $language-checked;
  color: #000;
}


// country flags

.language__container--ua .language__label {
  background-image: url("../../../../public/img/ua.svg");
}

.language__container--en .language__label {
  background-image: url("../../../../public/img/gb.svg");
}