/*
-----------------------------------------------------------------------
	Rental CSS
-----------------------------------------------------------------------
*/

.rental-filter-menu {
    margin-bottom: 63px;
    @media #{$lg-device} {
        margin-bottom: 50px;
    }
    @media #{$md-device} {
        margin-bottom: 35px;
    }
    button {
        background-color: transparent;
        text-transform: uppercase;
        border: none;
        box-shadow: none;
        color: $black;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.1;
        margin-right: 38.74px;
        padding: 0;
        position: relative;
        @include transition(0.3s);
        @media #{$sm-device} {
            margin-right: 25px;
        }
        &:last-child {
            margin-right: 0;
        }
        &:after {
            background-color: #535353;
            bottom: 0;
            content: "";
            height: 1px;
            position: absolute;
            right: 0;
            width: 0;
            @include transition(0.3s);
        }
        &.is-checked,
        &:hover {
            &:after {
                left: 0;
                width: 100%;
            }
        }
    }
}

.rental-area {
    &.rental-masonry-area {
        padding: 0 0 100px;
        @media #{$md-device} {
            padding: 0 0 80px;
        }
        @media #{$sm-device} {
            padding: 0 0 60px;
        }
        .container {
            @media #{$sm-device} {
                max-width: none;
            }
        }
    }
}

.post-items-style1 {
    .post-item {
        margin-bottom: 51px;
        @media #{$xs-device} {
            margin-bottom: 30px;
        }
        .thumb {
            margin-bottom: 20px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                @include transition(all 0.8s ease-in-out);
            }
        }
        .content {
            .title {
                color: $black;
                font-family: $font-current-theme1;
                font-size: 20px;
                font-weight: $font-weight-bold;
                line-height: 30px;
                letter-spacing: 0;
                margin-bottom: 22px;
                @media #{$xs-device} {
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 15px;
                }
                a {
                    border-bottom: 1px solid transparent;
                    color: $black;
                    position: relative;
                    &:hover {
                        border-bottom-color: #535353;
                    }
                }
            }
            .meta {
                color: $black-999;
                font-size: 13px;
                letter-spacing: -0.25px;
                margin-bottom: 8px;
                a {
                    margin-right: 5px;
                    color: $black-999;
                    .icon {
                        color: $body-color;
                        padding-right: 7px;
                    }
                    &:hover {
                        color: $black;
                    }
                }
                span {
                    padding: 0 6px;
                }
            }
            .btn-link {
                color: $black-999;
                font-size: 13px;
                font-weight: $font-weight-bold;
                line-height: 1;
                letter-spacing: 0.25px;
                text-transform: uppercase;
                &:hover {
                    color: $black;
                }
            }
            &.post-text {
                padding-top: 20px;
            }
        }
        &:hover {
            .thumb {
                img {
                    @include scale(1.2);
                }
                &.post-video {
                    img {
                        transform: none;
                    }
                }
            }
        }
    }
}

.rental-details-area {
    padding: 10px 0 10px;
    position: relative;
    @media #{$md-device} {
        padding: 8px 0 8px;
    }
    @media #{$sm-device} {
        margin: 0 30px;
        padding: 6px 0 6px;
    }
    @media #{$xs-device} {
        margin: 0 15px;
    }
    @media #{$xxs-device} {
        padding: 6px 0 8px;
    }
}

.post-details-content {
    background-color: $white;
    margin: 0 auto;
    max-width: 1310px;
    .post-details-body {
        margin: 0 auto;
        max-width: 990px;
        padding: 20px 15px 10px;
        @media #{$lg-device} {
            padding: 20px 15px 10px;
        }
        @media #{$md-device} {
            padding: 20px 15px 10px;
        }
        @media #{$xxs-device} {
            padding: 16px 15px 8px;
        }
        .content {
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 20px;
            padding-bottom: 29px;

            .title {
                color: $black;
                font-family: $font-current-theme1;
                font-size: 30px;
                font-weight: $font-weight-bold;
                letter-spacing: -0.25px;
                margin-bottom: 24px;
                @media #{$md-device} {
                    font-size: 28px;
                }
                @media #{$xs-device} {
                    font-size: 24px;
                }
                @media #{$xxs-device} {
                    font-size: 20px;
                }
            }
            .rent-price {
                font-size: 22px;
                font-weight: $font-weight-bold;

                .rent-price-value {
                    color: #669900;
                }
            }
            .full-price {
                font-size: 16px;
                font-weight: $font-weight-bold;

                .rent-price-value {
                    font-size: 18px;
                }
            }
            .interested-label {
                font-size: 18px;
                font-weight: $font-weight-black;
                font-style: italic;
                color: #996600;
            }
            .meta {
                color: $body-color;
                font-size: 14px;
                letter-spacing: 0;
                margin-bottom: 43px;
                @media #{$xs-device} {
                    font-size: 13px;
                    margin-bottom: 40px;
                }
                a {
                    margin-right: 5px;
                    color: $body-color;
                    &:hover {
                        color: $black;
                        i {
                            color: $black;
                        }
                    }
                    &.author {
                        i {
                            font-size: 14px;
                            top: -1px;
                            @media #{$xs-device} {
                                font-size: 12px;
                            }
                        }
                    }
                }
                i {
                    color: $black-999;
                    font-size: 18px;
                    margin-right: 9px;
                    position: relative;
                    top: 2px;
                    @include transition(0.3s);
                    @media #{$xs-device} {
                        font-size: 14px;
                    }
                }
                span {
                    color: $gray-ccc;
                    display: inline-block;
                    margin: 0 18px;
                    @include transition(0.3s);
                    @media #{$sm-device} {
                        margin: 0 6px;
                    }
                }
            }
            p {
                color: $body-color;
                font-size: 15px;
                letter-spacing: 0.35px;
                line-height: 26px;
                margin-bottom: 16px;
                &.text-block {
                    color: $black;
                    font-weight: $font-weight-bold;
                }
            }
            span {
                &.text-block {
                    color: $black;
                    font-weight: $font-weight-bold;
                }
            }
            ul {
                color: $body-color;
                font-size: 15px;
                letter-spacing: 0.35px;
                line-height: 26px;
                margin-bottom: 16px;
                margin-left: 20px;
                li {
                    list-style:disc;
                    i {
                        color: $black-999;
                        font-size: 12px;
                        @include transition(0.3s);
                    }
                    &:hover {
                        i {
                            color: $body-color;
                        }
                    }
                }
            }
            h4 {
                font-size: 24px;
                letter-spacing: -0.25px;
                margin-bottom: 34px;
                margin-top: 8px;
                @media #{$sm-device} {
                    font-size: 22px;
                    margin-bottom: 25px;
                    margin-top: 5px;
                }
            }
        }
        .widget-tags {
            color: $black-999;
            font-size: 14px;
            letter-spacing: 0.35px;
            line-height: 26px;
            margin-bottom: 21px;
            span {
                color: $black;
                font-weight: $font-weight-bold;
                margin-right: 6px;
                text-transform: uppercase;
            }
            a {
                margin-right: 5px;
                color: $body-color;
                &:hover {
                    color: $black;
                }
            }
        }
        .bread-crumbs {
            color: $black-999;
            font-size: 14px;
            letter-spacing: 0;
            margin-bottom: 67px;
            @media #{$md-device} {
                margin-bottom: 40px;
            }
            @media #{$xxs-device} {
                margin-bottom: 30px;
            }
            a {
                color: $black-999;
                &:hover {
                    color: $black;
                }
            }
            span {
                padding: 0 8px;
                &.active {
                    color: $black;
                    padding: 0;
                }
            }
        }
    }
    .post-gallery {
        .gallery-slider {
            .swiper-slide {
                img {
                    width: 100%;
                }
            }
        }
        .swiper-pagination {
            &.swiper-pagination-bullets {
                position: absolute;
                bottom: 29px !important;
                left: 140px !important;
                width: auto;
                @media #{$lg-device} {
                    left: 100px !important;
                }
                @media #{$xs-device} {
                    bottom: 20px !important;
                    left: 50px !important;
                }
                @media #{$xxs-device} {
                    left: 30px !important;
                }
                .swiper-pagination-bullet {
                    background-color: transparent !important;
                    height: 13px !important;
                    margin: 0 8.13px !important;
                    opacity: 1 !important;
                    width: 13px !important;
                    @include transition(0.3s);
                    border-radius: 100%;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 100%;
                        width: 8px;
                        height: 8px;
                        background: #d8d8d8;
                        z-index: 1;
                        transition: all 0.3s ease-in-out;
                    }
                    @media #{$xxs-device} {
                        margin: 0 3px !important;
                    }
                    &:first-child {
                        margin-left: 0 !important;
                    }
                    &:last-child {
                        margin-right: 0 !important;
                    }
                    &.swiper-pagination-bullet-active,
                    &:hover {
                        &:before {
                            width: 12px;
                            height: 12px;
                            opacity: 1;
                            background: $theme-color;
                        }
                    }
                }
            }
        }
    }
}


.widget-item {
    .widget-title {
        color: $black;
        font-size: 18px;
        letter-spacing: 0;
        margin-bottom: 47px;
        text-transform: uppercase;
    }
}

.widget-tags {
    ul {
        li {
            color: $black-999;
            display: inline-block;
            font-size: 14px;
            font-weight: $font-weight-normal;
            margin: 0 6px 10px 0;
            a {
                background-color: $white-f3;
                border-radius: 0;
                color: $black-999;
                display: inline-block;
                font-size: 14px;
                font-weight: $font-weight-normal;
                letter-spacing: 0.148px;
                line-height: 1;
                padding: 7.5px 14px;
                &:hover {
                    background-color: $theme-color;
                    color: $white;
                }
            }
            &:nth-child(4) {
                a {
                    padding: 7.5px 10px;
                }
            }
        }
    }
    &.tags-style2 {
        padding-top: 1px;
        ul {
            li {
                a {
                    background-color: transparent;
                    border: 1px solid #d7d7d7;
                    letter-spacing: -0.15px;
                    padding: 6.5px 12.41px;
                    &:hover {
                        background-color: $theme-color;
                        border-color: $theme-color;
                        color: $white;
                    }
                }
            }
        }
    }
}

.widget-categories {
    ul {
        li {
            border-bottom: 1px solid #d3d8dc;
            color: $black;
            font-size: 14px;
            padding: 17px 0;
            letter-spacing: -0.093px;
            line-height: 1;
            margin-bottom: 0;
            text-transform: uppercase;
            a {
                color: $black;
                display: block;
                font-size: 14px;
                span {
                    float: right;
                }
                &:hover {
                    color: $theme-color;
                }
            }
            &:first-child {
                padding-top: 1px;
            }
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.widget-blog-post {
    ul {
        li {
            border-bottom: 1px solid #d3d8dc;
            display: flex;
            margin-bottom: 19px;
            padding-bottom: 19px;
            position: relative;
            .thumb {
                margin-right: 20px;
                min-width: 79px;
                img {
                    border-radius: 0;
                    width: auto;
                }
            }
            .content {
                position: relative;
                top: -5px;
                a {
                    color: $black;
                    display: block;
                    font-size: 14px;
                    font-weight: $font-weight-normal;
                    letter-spacing: 0;

                    &:hover {
                        color: $theme-color;
                    }
                }
                span {
                    color: #aba5a5;
                    display: block;
                    font-size: 14px;
                }
            }
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.widget-search-box {
    form {
        input {
            background-color: $white-f5;
            border: none;
            box-shadow: none;
            height: 51px;
            padding: 10px 30px;
            width: 100%;
            @include placeholder {
                color: $black-999;
                font-size: 14px;
                letter-spacing: -0.15px;
            }
        }
    }
}

.sidebar-wrapper {
    &.blog-sidebar-wrapper {
        padding-left: 70px;
        padding-top: 3px;
        @media #{$lg-device} {
            padding-left: 15px;
        }
        @media #{$md-device} {
            padding-left: 0;
        }
        .widget-item {
            .widget-title {
                letter-spacing: 0.323px;
                margin-bottom: 37px;
            }

            .widget-search-box {
                margin-bottom: 71px;
            }

            .widget-categories {
                margin-bottom: 71px;
                ul {
                    margin-bottom: 0;
                }
            }

            .widget-blog-post {
                margin-bottom: 77px;
                padding-top: 7px;
            }

            .widget-tags {
                &.tags-style2 {
                    ul {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
