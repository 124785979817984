/*
-----------------------------------------------------------------------
	Price CSS
-----------------------------------------------------------------------
*/

.price-filter-menu {
    margin-bottom: 63px;
    @media #{$lg-device} {
        margin-bottom: 50px;
    }
    @media #{$md-device} {
        margin-bottom: 35px;
    }
    button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $black;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.1;
        margin-right: 38.74px;
        padding: 0;
        position: relative;
        @include transition(0.3s);
        @media #{$sm-device} {
            margin-right: 25px;
        }
        &:last-child {
            margin-right: 0;
        }
        &:after {
            background-color: #535353;
            bottom: 0;
            content: "";
            height: 1px;
            position: absolute;
            right: 0;
            width: 0;
            @include transition(0.3s);
        }
        &.is-checked,
        &:hover {
            &:after {
                left: 0;
                width: 100%;
            }
        }
    }
}

.price-area {
    &.price-masonry-area {
        padding: 0 0 100px;
        @media #{$md-device} {
            padding: 0 0 80px;
        }
        @media #{$sm-device} {
            padding: 0 0 60px;
        }
        .container {
            @media #{$sm-device} {
                max-width: none;
            }
        }
    }
}

.cooperation-text {
    font-size: 14px;
    @media #{$lg-device} {
        font-size: 14px;
    }
    @media #{$md-device} {
        font-size: 13px;
    }
    @media #{$sm-device} {
        font-size: 12px;
    }
    p {
        margin: 0;
    }
}

.price-items-style1 {
    .post-item {
        margin-bottom: 51px;
        background-color: #f0f6f6;
        @media #{$xs-device} {
            margin-bottom: 30px;
        }
        .content {
            padding-bottom: 22px;
            text-align: center;
            .title {
                color: $black;
                font-family: $font-current-theme1;
                font-size: 26px;
                font-weight: $font-weight-bold;
                line-height: 36px;
                letter-spacing: 0;
                padding: 25px 22px 0 25px;
                @media #{$xs-device} {
                    font-size: 22px;
                    line-height: 30px;
                    padding: 17px 15px 0 17px;
                }
            }
            .price {
                font-size: 40px;
                font-weight: 300;
                padding-bottom: 10px;
                border-bottom: #ddd 2px solid;
                margin: 0 10px 0 10px;
                @media #{$xs-device} {
                    font-size: 32px;
                }
            }
            .desc {
                li {
                    font-size: 15px;
                    padding: 10px 15px 0 15px;
                }
            }
            &.post-text {
                padding-top: 20px;
            }
            &:hover {
                background-color: #ebfcfc;
            }
        }
    }
}
